<template functionnal>
  <div class="template pb-4">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos ateliers | <span class="marker-effect">Reservés aux apprenants</span></h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4">
          <div class="row">
            <h5>Les ateliers de conversation (gratuit, 1x/semaine pendant la période de cours)</h5>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Public et prérequis</div>
            <div>
              - Faux débutant et Intermédiaire : A1 - A2 <br />
              - Avancé et Confirmé : B1 - B2 <br />
              - Grand débutant <br />
              - Renforcement à visée professionnelle.<br />
            </div>
          </div>

          <div class="row mt-1">
            <img
              src="@/assets/images/07-atelier-conversation.jpg"
              alt="cour de francais visee pro"
            />
          </div>

          <div class="row mt-5">
            <h6>Descriptions des cours</h6>
            <div class="col-12 p-0">
              Tous les mercredis
              <ul>
                <li><span class="font-semibold">Lieu de l’atelier:</span> Femmes Initiatives</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-6 mt-4">
          <div class="row">
            <h5>Les ateliers d’informatiques</h5>
          </div>

          <div class="row">
            <div class="col-12 p-0 font-bold">Public et prérequis</div>
            <div>
              - Grand débutant<br />
              - Renforcement à visée professionnelle<br />
            </div>
          </div>

          <div class="row mt-12" style="height: 393px; overflow: hidden;">
            <img
              style="margin-top: -4px;"
              src="@/assets/images/08-atelier-informatique.jpg"
              alt="cour de francais visee pro"
            />
          </div>

          <div class="row mt-5">
            <h6>Descriptions des cours</h6>

            <div class="col-12 p-0">
              Tous les mercredis
              <ul>
                <li><span class="font-semibold">Lieu de l'atelier:</span> Femmes Initiatives</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6 mt-4">
          <div class="row">
            <h5>L’atelier de lecture à voix haute</h5>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Public et prérequis</div>
            <div>
              Intermédiaire et Avancé : A2 - B1
            </div>
          </div>

          <div class="row mt-2" style="height: 398px; overflow: hidden;">
            <img src="@/assets/images/10-atelier-apprenants.jpg" alt="cour de francais visee pro" />
          </div>

          <div class="row mt-3">
            <h6>Descriptions des cours</h6>

            <div class="col-12 p-0">
              Tous les vendredis
              <ul>
                <li><span class="font-semibold">Lieu de l’atelier:</span> Femmes Initiatives</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-6 mt-4">
          <div class="row">
            <h5>Les activités transversales</h5>
          </div>

          <div class="row">
            <div class="col-12 p-0 font-bold">Public et prérequis</div>
            <div>
              - Tous apprenants
            </div>
          </div>

          <div class="row mt-2">
            <img src="@/assets/images/09-atelier-transver.jpg" alt="cour de francais visee pro" />
          </div>

          <div class="row mt-2">
            <h6>Descriptions des cours</h6>

            <div class="col-12 p-0">
              Actions ponctuelles toute l’année
              <ul>
                <li>Ateliers cuisine</li>
                <li>Sorties culturelles</li>
                <li>Soirées jeux de société, ...</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
